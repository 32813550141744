import React from 'react'
import { graphql, Link } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SEO from 'components/seo'
import PropTypes from 'prop-types'
import { composeClasses, jsonId } from 'utils'
import 'styles/global.css'
import parse from 'html-react-parser'
import SocialLinks from 'components/socialLinks'
import HelmetExport from 'react-helmet'
import ContactFormButton from 'components/contactFormButton.js'
import {
  flexColumn,
  flexItem,
  partnershipListContainer,
  centerText,
  workspaceSectionContent,
  workspaceSectionInner,
  workspaceSection,
  displayImageLeft,
  strategicPartnershipWrapper,
  partnershipAttachments
} from '../styles/partnership.module.css'
import {
  cloudNativeHeadingWrapper
} from '../styles/cloudNativeSoftwareEngineering.module.css'
import {
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'
import {
  googleWorkspaceWrapper
} from '../styles/workspace.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Partnerships" }) {
        edges {
          node {
            partnershipsFieldGroup {
              partnershipsHeroHeading
              partnershipsHeroText
              partnershipsStrategicSectionHeader
              partnershipsCtaText
              partnershipsCtaLink
              partnershipsRepeater {
                partnershipTitle
                partnershipIcon {
                  sourceUrl
                }
                partnershipDescription
                partnershipAttachments {
                  sourceUrl
                }
              }
              workspaceSectionHeader
              workspaceSectionDescription
              workspaceSectionHyperlinkText
              workspaceSectionImage {
                sourceUrl
              }
              cloudPlatformSectionHeader
              cloudPlatformSectionDescription
              cloudPlatformSectionHyperlinkText
              cloudPlatformSectionImage {
                sourceUrl
              }
            }
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const Partnership = ({ data, pageContext }) => {
  const partnershipsData = data.wordPress.pages.edges[0].node.partnershipsFieldGroup
  const partnershipsRepeater = data.wordPress.pages.edges[0].node.partnershipsFieldGroup.partnershipsRepeater
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  const PartnershipList = ({ partnershipsRepeater = [] }) => {
    return (
      partnershipsRepeater.length &&
      partnershipsRepeater.map((item, index) => {
        return (
          <div
            className={flexItem}
            key={index}>
            <img
              alt='partnershipIcon'
              height={48}
              src={item.partnershipIcon.sourceUrl}
              width={
                item.partnershipTitle === 'Microsoft'
                  ? 166
                  : item.partnershipTitle === 'AWS'
                    ? 80
                    : item.partnershipTitle === 'Rancher'
                      ? 102
                      : 48
              }
            />
            <h3>{item.partnershipTitle}</h3>
            {item.partnershipDescription && (
              <div>{parse(item.partnershipDescription)}</div>
            )}
            {item.partnershipAttachments.length > 0 &&
              <div className={partnershipAttachments}>
                {item.partnershipAttachments.map((item, index) => {
                  return (
                    <img
                      height={120}
                      src={item.sourceUrl}
                      width={120}
                    />
                  )
                })}
              </div>
            }
          </div>
        )
      })
    )
  }

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='Our Partnership Information'
        title='Our Partnerships' />
      <div className={googleWorkspaceWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Partnerships'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
          <div className={cloudNativeHeadingWrapper}>
            <h1>{partnershipsData.partnershipsHeroHeading}</h1>
            <p>{partnershipsData.partnershipsHeroText}</p>
            <ContactFormButton ctaText={partnershipsData.partnershipsCtaText} />
          </div>
        </div>
        <div className={workspaceSection}>
          <div className={workspaceSectionInner}>
            <div className={workspaceSectionContent}>
              <h2>{partnershipsData.workspaceSectionHeader}</h2>
              <p>{partnershipsData.workspaceSectionDescription}</p>
              <Link
                to={'/google-workspace'}
              >
                <span>{partnershipsData.workspaceSectionHyperlinkText}</span>
              </Link>
            </div>
            <div>
              <img src={partnershipsData.workspaceSectionImage.sourceUrl} />
            </div>
          </div>
        </div>
        <div className={composeClasses(workspaceSection, displayImageLeft)}>
          <div className={workspaceSectionInner}>
            <div className={workspaceSectionContent}>
              <h2>{partnershipsData.cloudPlatformSectionHeader}</h2>
              <p>{partnershipsData.cloudPlatformSectionDescription}</p>
              <Link
                to={'/google-cloud-platform'}
              >
                <span>{partnershipsData.cloudPlatformSectionHyperlinkText}</span>
              </Link>
            </div>
            <div>
              <img src={partnershipsData.cloudPlatformSectionImage.sourceUrl} />
            </div>
          </div>
        </div>
        <div className={strategicPartnershipWrapper}>
          <div className={centerText}>
            <h2>{partnershipsData.partnershipsStrategicSectionHeader}</h2>
          </div>
          <div className={partnershipListContainer}>
            <div className={flexColumn}>
              {PartnershipList({ partnershipsRepeater })}
            </div>
          </div>
        </div>
        <SocialLinks
          hasWhiteBackground
          socialLinks={footerData.socialLinks} />
      </div>
    </>
  )
}

Partnership.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object.isRequired
}

export default Partnership
