// extracted by mini-css-extract-plugin
export var flexContainer = "partnership-module--flexContainer--rX3FU";
export var flexContainerInner = "partnership-module--flexContainerInner--nbh8f";
export var centerText = "partnership-module--centerText---1TpU";
export var flexColumn = "partnership-module--flexColumn--JPrL5";
export var flexItem = "partnership-module--flexItem--1OeZy";
export var partnershipListContainer = "partnership-module--partnershipListContainer--xgtjX";
export var strategicPartnershipWrapper = "partnership-module--strategicPartnershipWrapper--1WYXe";
export var workspaceSection = "partnership-module--workspaceSection--k7+3E";
export var displayImageLeft = "partnership-module--displayImageLeft--dPmlx";
export var workspaceSectionInner = "partnership-module--workspaceSectionInner--A0cwV";
export var workspaceSectionContent = "partnership-module--workspaceSectionContent--wj543";
export var contactContainer = "partnership-module--contactContainer--puEqR";
export var partnershipAttachments = "partnership-module--partnershipAttachments--3rfBY";